import React, { useEffect, useRef, useState } from "react";
import CityList from "../cityList";
import GoogleTranslateWidget from "../GoogleTranslateWidget";

const MobileHeader = ({ props, toggleLoginModalNew, logoutNew, sessionData, vendorData }) => {
  const [section, setSection] = useState(null);
  const [options, setOptions] = useState(false);
  const [popular, setPopular] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [cityPopup, setCityPopup] = useState(false);

  const ref = useRef(null);
  useEffect(() => { }, [props]);
  const handleNavSections = (index) => {
    if (index == section) {
      setSection(null);
    } else {
      setSection(index);
    }
  };
  const checkSessionData = () => {
    toggleLoginModalNew(true);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setNavbar(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [navbar]);
  const handleCityPopup = () => {
    setCityPopup(!cityPopup);
  };
  return (
    <>
      <div id="header_mobile_handler">
        <img
          src="https://media.bookeventz.com/html/ui_website/images/hamburger.svg"
          alt="Open side nav"
          onClick={() => setNavbar(true)}
          fetchpriority="low"
          data-cache-control="public, max-age=31536000"
        />
        <a
          href={`https://www.bookeventz.com/`}
          target="_blank"
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
          rel="noopener noreferrer"
        >
          <img src="//media.bookeventz.com/html/bookeventz.com/asset/images/bzMainLogo.svg" alt="Home page" fetchpriority="low" data-cache-control="public, max-age=31536000" />
        </a>

        {(props?.pageType === "homeBlogListing" || props?.pageType === "categoryBlogListing" || props?.pageType === "authorBlogListing" || props?.pageType === "tagBlogListing") && (
          <div className="city-change lang" id="change_blog_language">
            <GoogleTranslateWidget />
          </div>
        )}
      </div>
      {navbar && (
        <div id="header_wrapper">
          <div className="sidebar" ref={ref}>
            <div className="profile">
              <div className="profile-icon">
                <img
                  src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/profile.svg"
                  alt="Profile icon "
                  fetchpriority="low"
                  data-cache-control="public, max-age=31536000"
                />
              </div>
              {sessionData && "userName" in sessionData ? (
                <p className="name">{sessionData?.userName}</p>
              ) : (
                <p className="name" onClick={() => checkSessionData()}>
                  {"Login or Register"}
                </p>
              )}
            </div>
            <hr />
            <div className="location" onClick={handleCityPopup}>
              <p>
                City: <span className="city">{props?.sessionData?.CityName ? props?.sessionData?.CityName : "Choose City"}</span>
              </p>
              <span className="dropdown-icon">
                <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/w_arrow.svg" alt="Dropw down" fetchpriority="low" data-cache-control="public, max-age=31536000" />
              </span>
            </div>
            {sessionData?.CityId && sessionData?.CityId == "1" && (
              <div id="mobile_number_section">
                <span id="city_controll_4">
                  <a
                    href="tel:9967581110"
                    target="_blank"
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/phone.svg"
                      alt="Call"
                      fetchpriority="low"
                      data-cache-control="public, max-age=31536000"
                      width="20"
                      height="20"
                    />
                    99675 81110
                  </a>
                </span>
                <span id="nh">Need help?</span>
              </div>
            )}
            <div className="menu">
              <div className="menu-item">
                {props?.NewsessionData !== null && props?.NewsessionData !== undefined && props?.NewsessionData?.CityUniqueLink == "destination-wedding" ? (
                  <span id="jhs7">
                    <span className="icon">
                      <img
                        src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/venue_new.svg"
                        alt="Venue "
                        fetchpriority="low"
                        data-cache-control="public, max-age=31536000"
                      />
                    </span>
                    <a
                      href={`https://www.bookeventz.com/banquets/${props?.NewsessionData?.CityUniqueLink}`}
                      target="_blank"
                      style={{
                        color: "inherit !important",
                        textDecoration: "none",
                      }}
                    >
                      Venues
                    </a>
                  </span>
                ) : (
                  <div id="jhs7" onClick={() => handleNavSections(0)}>
                    <span className="icon">
                      <img
                        src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/venue_new.svg"
                        alt="Venue "
                        fetchpriority="low"
                        data-cache-control="public, max-age=31536000"
                      />
                    </span>
                    <span>Venues</span>
                    <span className="dropdown-icon">
                      <img id={section == 0 ? "rotate_icon" : ""} src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/dropDown.svg" alt="Drop down" />
                    </span>
                  </div>
                )}

                {section == 0 && (
                  <div id="ha76">
                    <span onClick={() => setPopular(!popular)}>
                      popular areas
                      <img
                        src={
                          popular
                            ? "https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/minu_s.svg"
                            : "https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/plus.svg"
                        }
                        alt="Popular areas"
                        fetchpriority="low"
                        data-cache-control="public, max-age=31536000"
                      />
                    </span>
                    {popular && (
                      <ul>
                        {props?.areaList &&
                          props.areaList.length > 0 &&
                          props.areaList.map((area, index) => {
                            return (
                              Number(area.isPopular) === 1 && (
                                <li key={index}>
                                  <a
                                    href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/${area.AreaUniqueLink}`}
                                    target="_blank"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "none",
                                    }}
                                    rel="noopener noreferrer"
                                  >
                                    {area.AreaName}
                                  </a>
                                </li>
                              )
                            );
                          })}
                      </ul>
                    )}
                    {!popular && (
                      <ul>
                        {props?.sessionData?.CityId == 1 && (
                          <li>
                            <a
                              href={`https://www.bookeventz.com/banquets/bookeventz-venues/${props?.sessionData?.CityUniqueLink}`}
                              target="_blank"
                              style={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                              rel="noopener noreferrer"
                            >
                              Bookeventz venues
                            </a>
                          </li>
                        )}
                        <li>
                          <a
                            href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}`}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                          >
                            banquet halls in {props?.sessionData?.CityName}
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/wedding`}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                          >
                            wedding venues in {props?.sessionData?.CityName}
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/birthday-party`}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                          >
                            birthday party halls in {props?.sessionData?.CityName}
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.bookeventz.com/banquets/${props?.sessionData?.CityUniqueLink}/5-star-hotels`}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                          >
                            5 Star Banquet Halls in {props?.sessionData?.CityName}
                          </a>
                        </li>
                        {/* <li>
                          <a
                            href={`https://www.bookeventz.com/${props?.sessionData?.CityUniqueLink}/banquet-halls-near-me`}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none",
                            }}
                            rel="noopener noreferrer"
                          >
                            Banquet Halls Near Me
                          </a>
                        </li> */}
                        {props?.sessionData?.CityId == 1 && (
                          <li id="mc_donald">
                            <a
                              href={`https://www.bookeventz.com/${props?.sessionData?.CityUniqueLink}/mcdonalds-birthday-party`}
                              target="_blank"
                              style={{
                                color: "inherit",
                                textDecoration: "none",
                              }}
                              rel="noopener noreferrer"
                            >
                              McDonalds Party Section
                            </a>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
              </div>
              {vendorData && vendorData?.length > 0 && vendorData != null &&
                <div className="menu-item">
                  <div id="jhs7" onClick={() => handleNavSections(1)}>
                    <span className="icon">
                      <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/camera.svg" alt="Vendors" fetchpriority="low" data-cache-control="public, max-age=31536000" />
                    </span>
                    <span>Vendors</span>
                    <span className="dropdown-icon">
                      <img id={section == 1 ? "rotate_icon" : ""} src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/dropDown.svg" alt="Drop icon " />
                    </span>
                  </div>
                  {section == 1 && (
                    <div id="ha76">
                      <ul id="vendor_ha76">
                        {vendorData &&
                          vendorData?.map((vendor, index) => {
                            let new_id = parseInt(vendor?.id);
                            let link = `https://www.bookeventz.com/${vendor?.uniqueLink}/${props?.sessionData?.CityUniqueLink}`;
                            switch (new_id) {
                              case 6:
                                link = `https://www.bookeventz.com/choreographer/${props?.sessionData?.CityUniqueLink}`;
                                break;
                              case 7:
                                link = `https://www.bookeventz.com/artists/comedian-in-india`;
                                break;
                              case 11:
                                link = `https://www.bookeventz.com/artists/anchors-in-india`;
                                break;
                              default:
                            }
                            return (
                              <li key={index}>
                                <a
                                  href={link}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                  rel="noopener noreferrer"
                                >
                                  {vendor?.name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
                </div>
              }
              <div className="menu-item">
                <div id="jhs7">
                  <span className="icon">
                    <img
                      src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/manager.svg"
                      alt="For corporates"
                      fetchpriority="low"
                      data-cache-control="public, max-age=31536000"
                    />
                  </span>
                  <span>
                    <a
                      href={`https://www.bookeventz.com/corporate-event-planning-portal`}
                      target="_blank"
                      style={{
                        color: "inherit !important",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      For Corporates
                    </a>
                  </span>
                </div>
              </div>
              <div className="menu-item">
                <div id="jhs7" onClick={() => handleNavSections(2)}>
                  <span className="icon">
                    <img
                      src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/love.svg"
                      alt="Invites and RSVP"
                      fetchpriority="low"
                      data-cache-control="public, max-age=31536000"
                    />
                  </span>
                  <span>Invites & RSVP</span>
                  <span className="dropdown-icon">
                    <img id={section == 2 ? "rotate_icon" : ""} src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/dropDown.svg" alt="Profile icon " />
                  </span>
                </div>
                {section == 2 && (
                  <div id="ha76">
                    <ul id="vendor_ha76">
                      <li>
                        <a
                          href={`https://www.bookeventz.com/invites/wedding-invitation-templates`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          Wedding
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/invites/birthday-invitation-templates`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          Birthday Party
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/invites/corporate-event-invitation-templates`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          Corporate
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="menu-item">
                <div id="jhs7" onClick={() => handleNavSections(3)}>
                  <span className="icon">
                    <img
                      src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/gal.svg"
                      alt="For real events"
                      fetchpriority="low"
                      data-cache-control="public, max-age=31536000"
                    />
                  </span>
                  <span>Real Events</span>
                  <span className="dropdown-icon">
                    <img id={section == 3 ? "rotate_icon" : ""} src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/dropDown.svg" alt="Profile icon " />
                  </span>
                </div>
                {section == 3 && (
                  <div id="ha76">
                    <ul id="vendor_ha76">
                      <li>
                        <a
                          href={`https://www.bookeventz.com/real-events/wedding`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          wedding
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/real-events/birthday`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          birthday party
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/real-events/conference`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          corporate event
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/real-events/cocktail`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          cocktail
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/real-events`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          All
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="menu-item">
                <div id="jhs7" onClick={() => handleNavSections(4)}>
                  <span className="icon">
                    <img
                      src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/blogger.svg"
                      alt="For Blogs"
                      fetchpriority="low"
                      data-cache-control="public, max-age=31536000"
                    />
                  </span>
                  <span>
                    <a
                      href={`https://www.bookeventz.com/blog`}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      rel="noopener noreferrer"
                    ></a>
                    Blog
                  </span>
                  {/* <span className="dropdown-icon">
                    <img id={section == 4 ? "rotate_icon" : ""} src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/dropDown.svg" alt="Profile icon " />
                  </span> */}
                </div>
                {/* {section == 4 && (
                  <div id="ha76">
                    <ul id="vendor_ha76">
                      <li>
                        <a
                          href={`https://www.bookeventz.com/blog/category/wedding-ideas`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          wedding
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/blog/category/birthday-party`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          birthday party
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/blog/category/cocktail-party`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          cocktail party
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/blog/category/corporate-party`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          corporate party
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://www.bookeventz.com/blog/category/top-venues`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          top venues
                        </a>
                      </li>
                    </ul>
                  </div>
                )} */}
              </div>
              <div className="menu-item">
                <div id="jhs7" onClick={() => handleNavSections(5)}>
                  <span className="icon">
                    <img src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/lamp.svg" alt="Ideas" fetchpriority="low" data-cache-control="public, max-age=31536000" />
                  </span>
                  <span>Ideas</span>
                  <span className="dropdown-icon">
                    <img id={section == 5 ? "rotate_icon" : ""} src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/dropDown.svg" alt="List with us" />
                  </span>
                </div>
                {section == 5 && (
                  <div id="ha76">
                    <ul id="vendor_ha76">
                      <li>
                        <a
                          href={`https://weddingideas.bookeventz.com/`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          wedding
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://corporateeventideas.bookeventz.com/`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          birthday party
                        </a>
                      </li>
                      <li>
                        <a
                          href={`https://birthdayideas.bookeventz.com/`}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none",
                          }}
                          rel="noopener noreferrer"
                        >
                          corporate party
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="menu-item">
                <div id="jhs7">
                  <span className="icon">
                    <img
                      src="https://media.bookeventz.com/html/bookeventz.com/images/headerFooterIcons/handshake_new.svg"
                      alt="Profile icon "
                      fetchpriority="low"
                      data-cache-control="public, max-age=31536000"
                    />
                  </span>
                  <span>
                    <a
                      href={`https://www.bookeventz.com/why-list-with-us`}
                      target="_blank"
                      style={{
                        color: "inherit",
                        textDecoration: "none",
                      }}
                      rel="noopener noreferrer"
                    >
                      List With Us
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cityPopup && (
        <CityList
          props={props}
          sessionData={props.sessionData}
          citySelected={props.sessionData?.CityUniqueLink ? props.sessionData?.CityUniqueLink : "mumbai"}
          newcitySession={props?.sessionData}
          cityList={props?.cityList}
          pageType={props?.pageType}
          handleCityPopup={handleCityPopup}
        />
      )}
    </>
  );
};

export default MobileHeader;
