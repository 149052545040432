import React, { useState, useEffect, useRef } from "react";
import { DatePicker } from "react-responsive-datepicker";
import moment from "moment";
import axios from "axios";
import querystring from "querystring";
import generalUtil from "../general/generalUtil";
import cookie from "react-cookies";
import apiUtils from "../../utils/apiUtils";
const Chatbot = ({ sessionData, event_Id, class_name, productDetails, flag, filters }) => {
  const [newEventEnquiryMessages, setNewEventEnquiryMessages] = useState();
  const [formData, setFormData] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [link, setLink] = useState("");
  const [concern, setConcern] = useState("");
  const [loading, setLoading] = useState(false);
  const [emptyInput, setEmptyInput] = useState(false);
  const [emptyGuest, setEmptyGuest] = useState(false);
  const [emptyGuestNew, setEmptyGuestNew] = useState(false);
  const [emptyInputArea, setEmptyInputArea] = useState(false);
  const [emptyInputAreaNew, setEmptyInputAreaNew] = useState(false);
  const [bot_section, setBot_section] = useState(false);
  const [userName, setUserName] = useState("");
  const [userNameNew, setUserNameNew] = useState("");
  const [showDate, setShowDate] = useState(false);
  const messagesEndRef = useRef(null);
  const [areaValue, setAreaValue] = useState("");
  const [areaSection, setAreaSection] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [emptyInputNew, setEmptyInputNew] = useState(false);
  const [concernError, setConcernError] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyName, setEmptyName] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [randomEmail, setRandomEmail] = useState("");
  const [eventIdError, setEventIdError] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [numberValue, setNumberValue] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    if (window.scrollY > 620) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);
  useEffect(() => {
    scrollToBottom();
  }, [newEventEnquiryMessages]);
  useEffect(() => {
    scrollToBottom();
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [loading]);
  const setQuestions = () => {
    let data = [];
    if (event_Id) {
      data = [
        {
          text: "Hello and Welcome! Please select from the below options:",
          options: [
            { value: "Existing Event Enquiry", label: "Existing Event Enquiry", trigger: "2" },
            { value: "New Vendor Registration", label: "New Vendor Registration", trigger: "3" },
          ],
          showButtons: true,
          firstButtons: true,
        },
      ];
    } else {
      data = [
        {
          text: "Hello and Welcome! Please select from the below options:",
          options: [
            { value: "New Event Enquiry", label: "New Event Enquiry", trigger: "1" },
            { value: "Existing Event Enquiry", label: "Existing Event Enquiry", trigger: "2" },
            { value: "New Vendor Registration", label: "New Vendor Registration", trigger: "3" },
            // {value: "Booking Assistance", label: "Booking Assistance", trigger: "4"},
          ],
          showButtons: true,
          firstButtons: true,
        },
      ];
    }
    setNewEventEnquiryMessages(data);
  };
  useEffect(() => {
    setQuestions();
  }, [event_Id]);

  const handleButtonClick = (option, index, trigger, name, label) => {
    let newMessages = [...newEventEnquiryMessages];
    let key;
    if (index === 0) {
      key = "enquiry_type";
    } else if (index === 1) {
      key = "ocassion";
    }
    let number;
    if (option == "Wedding") {
      number = 10;
    } else if (option == "Corporate Event") {
      number = 39;
    } else if (option == "Social Get together") {
      number = 6;
    }
    if (trigger > 4) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ocassion_name: number,
        ocassion_Id: option,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        event_type: option,
      }));
    }
    newMessages[index].showButtons = false;
    if (trigger === 2) {
      newMessages.push({
        text: `Kindly share your name.`,
        showButtons: true,
        userText: `${option}`,
        showNameFieldNew: true,
      });
    } else if (trigger === 3) {
      newMessages.push({
        text: `Kindly share your name.`,
        userText: `${option}`,
        showName: true,
      });
    } else if (trigger === 4) {
      console.log("Third button");
    } else {
      if (index === 0) {
        const textMessage =
          flag && flag == "yes" && productDetails !== null
            ? `Greetings from ${productDetails?.RestaurantName}. I'm here to get details about your event so that we can share the best quote with you. May I know your name?`
            : `I'm here to help you find the perfect venue for your event. May I know your name?`;

        newMessages.push({
          text: textMessage,
          userText: `${option}`,
          showNameField: true,
        });
      } else {
        newMessages.push({
          userText: `${option}`,
          text: `Great! Could you tell me the number of guests you are expecting at your ${option}?`,
          input: true,
          key: "guests",
        });
      }
    }
    setNewEventEnquiryMessages(newMessages);
  };

  const handleUserNameNew = (value, index) => {
    if (!value) {
      setEmptyInputNew(true);
      return;
    } else {
      setEmptyInputNew(true);
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].showNameFieldNew = false;
      newMessages.push({
        userText: `${value}`,
        text: `Please enter your event's id.`,
        eventField: true,
      });
      setNewEventEnquiryMessages(newMessages);
      setFormData({ ...formData, user_name_new: value });
    }
  };
  const handleUser = (value, index, key) => {
    if (!value) {
      setEmptyName(true);
      return;
    } else {
      setEmptyName(true);
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].showName = false;
      newMessages.push({
        userText: `${value}`,
        text: `Please enter you number`,
        userNumberNew: true,
      });
      setNewEventEnquiryMessages(newMessages);
      setFormData({ ...formData, user_name: value });
    }
  };
  const handleUserName = (value, index, key) => {
    if (!value) {
      setEmptyInput(true);
      return;
    } else {
      setEmptyInput(true);
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].showNameField = false;
      newMessages.push({
        userText: `${value}`,
        text: `Hi ${value}. What is the type of event you're planning?`,
        options: [
          { value: "Wedding", label: "Wedding", trigger: "5" },
          { value: "corporate-event", label: "Corporate Event", trigger: "6" },
          { value: "social-gathering", label: "Social Get together", trigger: "7" },
        ],
        showButtons: true,
      });
      setNewEventEnquiryMessages(newMessages);
      setFormData({ ...formData, user_name: value });
    }
  };
  const handleInputSubmitNew = async (value, index, key) => {
    try {
      const data = await validate();
      if (!value) {
        setEmptyGuestNew(true);
        return;
      } else if (data === "false") {
        setEventIdError(true);
        setTimeout(() => {
          setEventIdError(false);
        }, 2000);
        return;
      } else {
        setEmptyGuestNew(true);
        let newMessages = [...newEventEnquiryMessages];
        newMessages[index].eventField = false;
        newMessages.push({
          userText: `${value}`,
          text: `Please tell us your concern, we will try to help you`,
          concernField: true,
        });
        setNewEventEnquiryMessages(newMessages);
        setFormData({ ...formData, event_Id: value });
      }
    } catch (error) {
      console.error("Error while validating:", error);
    }
  };
  const handleInputSubmit = (value, index, key) => {
    if (!value) {
      setEmptyGuest(true);
      return;
    } else {
      setEmptyGuest(true);
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].input = false;
      newMessages.push({
        userText: `${value}`,
        text: `You're expecting ${value} guests. Kindly confirm your event date:`,
        datePicker: true,
      });
      setNewEventEnquiryMessages(newMessages);
      setFormData({ ...formData, [key]: value });
    }
  };
  const handleEmail = (value, index, key) => {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let testEmail = emailPattern.test(value);
    if (!testEmail) {
      setEmptyEmail(true);
      return;
    } else {
      setEmptyEmail(true);
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].showEmail = false;
      newMessages.push({
        userText: `${value}`,
        text: `Thanks for sharing the contact details. Kindly choose the category you want to enlist yourselves.`,
        // clickable_link: "https://venue.bookeventz.com/login?signUp=1",
        // ty_text:
        //   "It's been a pleasure assisting you! If you have any more questions or need further assistance, feel free to ask. Thank you for listing with us!",
        showVenueTyoe: true,
        // showLinkNew: true,
      });
      setNewEventEnquiryMessages(newMessages);
      setFormData({ ...formData, email: value });
    }
  };
  const handleVenueType = (value, index) => {
    let newMessages = [...newEventEnquiryMessages];
    newMessages[index].showVenueTyoe = false;
    newMessages.push({
      userText: `${value}`,
      text: `Signup on the link and create your profile on  Bookeventz. Get ready to boost your business multifolds!`,
      clickable_link: "https://venue.bookeventz.com/login?signUp=1",
      ty_text: "It's been a pleasure assisting you! If you have any more questions or need further assistance, feel free to ask. Thank you for listing with us!",
      showLinkNew: true,
      endButton: true,
    });
    setTimeout(() => {
      sendMail();
    }, 1000);
    setNewEventEnquiryMessages(newMessages);
    setFormData({ ...formData, venue_type: value });
  };
  const handleDateSelect = (date, index) => {
    setStartDate(date);
    setFormData({ ...formData, eventDate: date });
    let newMessages = [...newEventEnquiryMessages];
    newMessages[index].datePicker = false;
    if (filters && "banquetArea" in filters) {
      handleAreaInputSubmit(filters.areaUniqueLink, 5, filters.banquetArea, filters.area);
    } else {
      newMessages.push({
        userText: `${moment(date).format("ll")}`,
        text: `Great! Could you tell me the preferred area for your event?`,
        preferredAreaInput: true,
      });
      setNewEventEnquiryMessages(newMessages);
    }
  };
  const handleAreaInputSubmit = (value, index, area_name, AreaId) => {
    setAreaSection(false);
    if (!value) {
      setEmptyInputArea(true);
      setTimeout(() => {
        setEmptyInputArea(false);
      }, 1000);
      return;
    } else if (value && !isNaN(value)) {
      setEmptyInputArea(true);
      setTimeout(() => {
        setEmptyInputArea(false);
      }, 1000);
      return;
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        preferredArea: AreaId,
        area_name: value,
      }));
      let newMessages = [...newEventEnquiryMessages];
      if (!filters || (filters !== null && filters !== undefined && !"banquetArea" in filters)) {
        newMessages[index].preferredAreaInput = false;
      }
      newMessages.push({
        userText: `${area_name}`,
        text: `Kindly Share your Phone Number for us to share the list of recommended venues.`,
        userNumber: true,
      });

      setNewEventEnquiryMessages(newMessages);
    }
  };
  const getUserNumberNew = (value, index, key) => {
    if (value.length > 10) {
      setEmptyInputAreaNew(true);
      setTimeout(() => {
        setEmptyInputAreaNew(false);
      }, 1000);
      return;
    } else if (value.length < 10) {
      setEmptyInputAreaNew(true);
      setTimeout(() => {
        setEmptyInputAreaNew(false);
      }, 1000);
      return;
    } else {
      setFormData({ ...formData, phone_number: value });
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].userNumberNew = false;
      newMessages.push({
        userText: `${value}`,
        text: `Kindly share your email.`,
        showEmail: true,
      });

      setNewEventEnquiryMessages(newMessages);
    }
  };

  const getUserNumber = (value, index, key) => {
    if (value.length > 10) {
      setEmptyInputArea(true);
      setTimeout(() => {
        setEmptyInputArea(false);
      }, 1000);
      return;
    } else if (value.length < 10) {
      setEmptyInputArea(true);
      setTimeout(() => {
        setEmptyInputArea(false);
      }, 1000);
      return;
    } else {
      setLoading(true);
      setFormData({ ...formData, phone_number: value });
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].userNumber = false;
      let textMessage;
      if (filters !== null && filters !== undefined && "banquetArea" in filters) {
        textMessage = `Perfect! Based on your inputs, here are  few other venue recommendations that matches your criteria: `;
      } else {
        textMessage = `Thank you for your response we have received your enquiry, we will get back to you shortly. In the mean time , here are some venues based on your inputs which you can check.`;
      }
      newMessages.push({
        userText: `${value}`,
        text: textMessage,
        link: true,
        showLink: true,
        endButton: true,
      });
      setTimeout(() => {
        let link = JSON.parse(localStorage.getItem("formData"));
        socialUserLogin(link);
      }, 2000);
      setNewEventEnquiryMessages(newMessages);
    }
  };
  const lastCall = (dashboardUrl) => {
    let newLink = "";
    setTimeout(() => {
      let link = JSON.parse(localStorage.getItem("formData"));
      if (filters !== null && filters !== undefined && "banquetArea" in filters) {
        newLink = dashboardUrl;
      } else {
        newLink = `https://www.bookeventz.com/banquets/${sessionData?.CityUniqueLink}/${link.area_name}/${link.ocassion_Id == "Social Get together" ? "social-gathering" : link.ocassion_Id.toLowerCase().replaceAll(" ", "-")
          }?capacity-range=${link.guests}`;
      }
      setLink(newLink);
      setLoading(false);
    }, 2000);
  };
  const getAreaList = (search) => {
    if (search.length == 0) {
      setAreaSection(false);
      return;
    }
    const url = `https://api.bookeventz.com/location/getArea?cityId=${sessionData?.CityId ? sessionData?.CityId : 1}&areaName=${search}`;
    axios.get(url).then((res) => {
      if (res.data.length > 0) {
        setAreaList(res.data);
        setAreaSection(true);
      }
    });
  };
  const generateRandomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  useEffect(() => {
    generateRandomEmail();
  }, []);
  // Function to generate a random email address
  const generateRandomEmail = () => {
    const domain = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com"]; // Add more domains if needed
    const randomUsernameLength = Math.floor(Math.random() * 10) + 5; // Random username length between 5 and 14
    const randomDomain = domain[Math.floor(Math.random() * domain.length)];
    const randomUsername = generateRandomString(randomUsernameLength);
    let email = randomUsername + "@" + randomDomain;
    setRandomEmail(email);
  };
  const socialUserLogin = async (link) => {
    let userData = JSON.parse(localStorage.getItem("formData"));
    await axios
      .post(
        "https://www.bookeventz.com/" + "login/socialLoginCheck",
        querystring.stringify({
          userEmail: randomEmail,
          userName: link?.user_name,
          userMobileNo: link?.phone_number,
          registrationType: "2",
          pictureUrl: "",
          city: sessionData?.CityId,
        })
      )
      .then((xhrResponse) => {
        if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
          let data = xhrResponse.data;
          guestUserEnquiry(data, true, userData);
        }
      });
  };
  const guestUserEnquiry = (payload, isSocial = false, userData) => {
    // let banquetDetails = this.state.banquetDetails;
    // let tempEnquiryParams = cookie.load("tempEnquiryParams");
    let params = {};
    params.city = sessionData?.CityId;
    params.area = userData?.preferredArea;
    params.budget = "";
    let urlSource = window.location.pathname;
    //This urlSource is stored in googleAnalytics table
    if (urlSource !== "") {
      let lastIndex = urlSource.indexOf("?") > -1 ? urlSource.indexOf("?") : urlSource.length;
      urlSource = urlSource.substring(0, lastIndex);
    }
    params.page_source = urlSource !== "" ? urlSource : "/";
    params.countryCode = "+91";
    params.occasion = userData?.ocassion_name;
    params.userName = userData?.user_name;
    params.userMobileNo = userData?.phone_number;
    params.userEmail = randomEmail;
    params.isMobileLogin = 1;
    params.eventDate = moment(userData.eventDate).format("YYYY-MM-DD");
    params.capacity = userData?.guests;
    params.time = "full day";
    params.eventSource = 2;
    axios.post("https://www.bookeventz.com/home/getListingResult", querystring.stringify(params)).then((xhrResponse) => {
      let data = xhrResponse.data;
      if (generalUtil.safeReturn(xhrResponse, "data", false) !== false) {
        let redirectURL = data.pageUrl + "&pageType=venueProduct";
        lastCall(redirectURL, "redirectURL");

        let userData = data;
        if (data.enquiryId == undefined || data.pageUrl == undefined) {
          generalUtil.callGaDashboard("Enquiry Not Working", "enquiry-not-working");
          let notWorkingParams = {};
          notWorkingParams.page_source = params.page_source;
          notWorkingParams.page_type = "venueListing";
          notWorkingParams.mode = isSocial ? "google" : "guest_login";
          notWorkingParams.enquiry_data = JSON.stringify(params);
          apiUtils.enquiryNotWorkingSource(notWorkingParams);
        } else {
          //Added For the Store the session data only before redirect call
          payload.isEnquiryCreated = true;
          if (typeof payload.error == "undefined") {
            cookie.save("sessionData", payload, { path: "/" });
          }
          localStorage.setItem("sliderPopupIsActive", "1", 86400000);
          //Add this to hide popup after enquiry
          localStorage.setItem("sliderPopupIsActive", "1", 86400000);
          cookie.save("userData", userData, { path: "/" });
          cookie.remove("tempEnquiryParams");
          generalUtil.callGaDashboard("venueListing" + " slider", "submit", "enquiry", data.enquiryId, "venueListing");
          let redirectURL = data.pageUrl + "&pageType=" + "venueListing";
        }
      }
    });
  };
  const getUserConcern = (value, index) => {
    if (!value) {
      setConcernError(true);
      return;
    } else {
      setConcernError(true);
      let newMessages = [...newEventEnquiryMessages];
      newMessages[index].concernField = false;
      newMessages.push({
        userText: `${value}`,
        text: `We have received your concern, our team will get back to you shortly.Thank you for your patience.`,
        endButton: true,
      });
      setTimeout(() => {
        sendMailNew();
      }, 1000);
      setNewEventEnquiryMessages(newMessages);
      setFormData({ ...formData, concern: value });
    }
  };
  const sendMail = async () => {
    let formData = JSON.parse(localStorage.getItem("formData"));
    let url = "https://api.bookeventz.com/send-venue-email-request";
    let data = {
      name: formData.user_name,
      email: formData.email,
      number: formData.phone_number,
      category: formData.venue_type,
    };
    await axios.post(url, querystring.stringify(data)).then((res) => {
      console.log(res, "response");
    });
  };
  const sendMailNew = async () => {
    let formData = JSON.parse(localStorage.getItem("formData"));
    let url = "https://api.bookeventz.com/send-event-enquiry-request";
    let data = {
      name: formData.user_name_new,
      eventId: formData.event_Id,
      concern: formData.concern,
    };
    await axios.post(url, querystring.stringify(data)).then((res) => {
      console.log(res, "response");
    });
  };
  const validate = async () => {
    let url = `https://api.bookeventz.com/events/check_id/${eventId}`;
    return await axios.get(url).then((res) => {
      return res.data.status;
    });
  };
  return (
    <>
      {!bot_section ? (
        <div
          id={isSticky ? "chat-icon-section" : "dont_show"}
          className={class_name ? class_name : ""}
          onClick={() => {
            setBot_section(true);
          }}
        >
          <img src="https://media.bookeventz.com/html/ui_website/blog-section/bot_chat.svg" alt="Chat Icon" width={"100px"} height={"105px"} data-cache-control="public, max-age=604800" />
        </div>
      ) : (
        <div className="chatbot-container">
          {eventIdError && (
            <div id="event_id_check" onClick={() => setEventIdError(false)}>
              <div id="event_id_check_new">
                <img src="https://media.bookeventz.com/html/ui_website/blog-section/cross_.jpeg" alt="Cross icon" style={{ height: "30px", width: "30px" }} />
                <span>Event id is not present.</span>
                <span>Please check and try again</span>
              </div>
            </div>
          )}
          <div id="top-bots-section">
            <div id="ops">
              <img src="https://media.bookeventz.com/html/ui_website/blog-section/bkz_icon.svg" alt="Bookeventz_Icon" />
              <span>Bookeventz</span>
            </div>
            <span
              id="doa9"
              onClick={() => {
                setBot_section(false), setQuestions();
              }}
            >
              ---
            </span>
          </div>
          {loading ? (
            <div id="loader_198">
              <span id="loading_text">Getting results ready</span>
              <div class="loader4"></div>
            </div>
          ) : (
            <div id="message_wrapper">
              {newEventEnquiryMessages !== undefined &&
                newEventEnquiryMessages.map((message, index) => (
                  <div key={index} className="message">
                    {message.userText && (
                      <div id="buio_wrapper_user">
                        <span id="bot_icon">
                          <img src="https://media.bookeventz.com/html/bookeventz.com/user_icon.svg" alt="Bookeventz_Icon" />
                        </span>
                        <span id="bot_response">{message.userText}</span>
                      </div>
                    )}

                    {message.text && (
                      <div id="buio_wrapper">
                        <span id="bot_icon">
                          <img src="https://media.bookeventz.com/html/ui_website/blog-section/bkz_icon.svg" alt="Bookeventz_Icon" />
                        </span>
                        <span id="bot_response">
                          {message.text}
                          {message.showLink ? (
                            <>
                              <a href={link} target="_blank" rel="noopener noreferrer" style={{ lineBreak: "anywhere", color: "red" }}>
                                {"Click here"}
                              </a>
                              <br />
                              {filters !== null && filters !== undefined && "banquetArea" in filters && (
                                <span>
                                  Would you like more details?.Please send an email on <a href="mailto:info@bookeventz.com">info@bookeventz.com</a>
                                </span>
                              )}
                            </>
                          ) : null}
                        </span>
                      </div>
                    )}
                    {message.showLinkNew && (
                      <div id="c097">
                        <div id="buio_wrapper">
                          <span id="bot_icon">
                            <img src="https://media.bookeventz.com/html/ui_website/blog-section/bkz_icon.svg" alt="Bookeventz_Icon" />
                          </span>
                          <span id="bot_response">
                            For venue / vendor sign up{" "}
                            <a href={message.clickable_link} target="_blank" rel="noopener noreferrer" style={{ lineBreak: "anywhere", color: "red" }}>
                              {"Click here"}
                            </a>
                          </span>
                        </div>

                        <div id="buio_wrapper">
                          <span id="bot_icon">
                            <img src="https://media.bookeventz.com/html/ui_website/blog-section/bkz_icon.svg" alt="Bookeventz_Icon" />
                          </span>
                          <span id="bot_response">{message.ty_text}</span>
                        </div>
                      </div>
                    )}
                    {message.showVenueTyoe && (
                      <div id="c097">
                        <div id="buio_wrapper">
                          <span id="bot_icon">
                            <img src="https://media.bookeventz.com/html/ui_website/blog-section/bkz_icon.svg" alt="Bookeventz_Icon" />
                          </span>
                          <span id="bot_response">
                            <div id="choose-vendors">
                              <select name="Select one" id="category_options" onChange={(e) => handleVenueType(e.target.value, index)}>
                                <option value="Venue">Venue</option>
                                <option value="Photographer">Photographer</option>
                                <option value="Event Planner">Event Planner</option>
                                <option value="Singer">Singer</option>
                                <option value="Decorator">Decorator</option>
                                <option value=">Mehendi artist">Mehendi artist</option>
                                <option value="Makeup artist">Makeup artist</option>
                                <option value="Musicians">Musicians</option>
                                <option value="Wedding Cards">Wedding Cards</option>
                                <option value="Designer">Designer</option>
                              </select>
                            </div>
                          </span>
                        </div>
                      </div>
                    )}
                    {message.endButton && (
                      <button
                        id="end_button"
                        onClick={() => {
                          setBot_section(false), setQuestions();
                        }}
                      >
                        End chat
                      </button>
                    )}
                    {message.options && message.showButtons && (
                      <div className="buttons">
                        {message.options?.map((option, optionIndex) => {
                          return (
                            <button key={optionIndex} onClick={() => handleButtonClick(option.label, index, parseInt(option.trigger), option.label, option.value)}>
                              {option.label}
                            </button>
                          );
                        })}
                      </div>
                    )}
                    {message.showNameField && (
                      <div id="trying">
                        <div id={emptyInput ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="Enter your name"
                            onChange={(e) => setUserName(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleUserName(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button onClick={() => handleUserName(userName, index, message.key)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.showName && (
                      <div id="trying">
                        <div id={emptyName ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="Enter your name"
                            onChange={(e) => setUserName(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleUser(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button onClick={() => handleUser(userName, index, message.key)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.concernField && (
                      <div id="trying">
                        <div id={concernError ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="Your concern"
                            onChange={(e) => setConcern(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                getUserConcern(e.target.value, index);
                              }
                            }}
                          />
                          <button onClick={() => getUserConcern(concern, index)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.showNameFieldNew && (
                      <div id="trying">
                        <div id={emptyInputNew ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="Your name"
                            onChange={(e) => setUserNameNew(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleUserNameNew(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button onClick={() => handleUserNameNew(userNameNew, index, message.key)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.userNumberNew && (
                      <div id="trying">
                        <div id={emptyInputAreaNew ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="number"
                            placeholder="Phone number..."
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                getUserNumberNew(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button onClick={() => getUserNumberNew(document.querySelector('input[type="number"]').value, index, message.key)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.userNumber && (
                      <div id="trying">
                        <div id={emptyInputArea ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="number"
                            placeholder="Phone number..."
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                getUserNumber(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button onClick={() => getUserNumber(document.querySelector('input[type="number"]').value, index, message.key)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.eventField && (
                      <div id="trying">
                        <div id={emptyGuestNew ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="number"
                            placeholder="Enter event id"
                            onChange={(e) => setEventId(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleInputSubmitNew(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button onClick={() => handleInputSubmitNew(document.querySelector('input[type="number"]').value, index, message.key)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.input && (
                      <div id="trying">
                        <div id={emptyGuest ? "input_empty" : "input_one"}>
                          <input
                            type="number"
                            placeholder="Enter number of guests"
                            onChange={(e) => setNumberValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleInputSubmit(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button
                            onClick={() => handleInputSubmit(numberValue, index, message.key)}>
                            Submit
                          </button>
                        </div>
                      </div>
                    )}

                    {message.showEmail && (
                      <div id="trying">
                        <div id={emptyEmail ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="example@gmail.com"
                            onChange={(e) => setEmailValue(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleEmail(e.target.value, index, message.key);
                              }
                            }}
                          />
                          <button onClick={() => handleEmail(emailValue, index, message.key)}>Submit</button>
                        </div>
                      </div>
                    )}
                    {message.datePicker && (
                      <div id="pick_date">
                        <button className="example-custom-input" onClick={() => setShowDate(true)}>
                          <img src="https://media.bookeventz.com/html/ui_website/blog-section/calendar.svg" alt="Calender Icon" /> Select Date
                        </button>

                        {showDate && (
                          <DatePicker
                            isOpen={true}
                            showTitle={false}
                            defaultValue={startDate}
                            headerFormat="DD, MM dd"
                            onChange={(date) => handleDateSelect(date, index)}
                            headerBackgroundColor={"#4a266e"}
                          />
                        )}
                      </div>
                    )}
                    {message.preferredAreaInput && (
                      <div id="trying">
                        <div id={emptyInputArea ? "input_empty" : "input_one"}>
                          <input
                            ref={inputRef}
                            type="text"
                            placeholder="Enter preferred area"
                            onChange={(e) => {
                              getAreaList(e.target.value), setAreaValue(e.target.value);
                            }}
                          />
                          {/* <button onClick={() => handleAreaInputSubmit(areaValue, index, areaValue, )}>
                          Submit
                        </button> */}
                          {areaSection && (
                            <ul>
                              {areaList?.map((area, i) => {
                                return (
                                  <li id="area_name" onClick={() => handleAreaInputSubmit(area.AreaUniqueLink, index, area.AreaName, area.AreaId)}>
                                    {area.AreaName}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                    <div ref={messagesEndRef} />
                  </div>
                ))}
            </div>
          )}
          <div id="trying" className="ty87">
            <div id="input_one">
              <input type="number" placeholder="Please wait..." disabled />
              <button>Submit</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Chatbot;
