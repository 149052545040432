import React, { useState, useEffect, useRef } from "react";
import axios from "axios"
import MobileHeader from "./Headers/mobileHeader";
import DesktopHeader from "./Headers/desktopHeader";
const NewHeader = ({ props, logoutNew, toggleLoginModalNew, sessionData, pageType, vendorData }) => {
  return (
    <>
      <DesktopHeader props={props} logoutNew={logoutNew} toggleLoginModalNew={toggleLoginModalNew} sessionData={sessionData} vendorData={vendorData} />
      {pageType !== null && pageType !== undefined && pageType !== "venueProduct" &&
        <MobileHeader props={props} logoutNew={logoutNew} toggleLoginModalNew={toggleLoginModalNew} sessionData={sessionData} vendorData={vendorData} />
      }
    </>
  );
};
;

export default NewHeader;
